<template>
  <div>
    <!--上传配置-->
    <uploader :browse_button="threeFile ? threeBrowse :[upDateType]" :url="url" :chunk_size="updateSize"
              :max_retries="3"
              :filters="myfilters"
              :disableBrowse="false"
              :multi_selection="threeFile ? false : true" :FilesAdded="filesAdded" :BeforeUpload="beforeUpload"
              :Error="error"
              :headers="myHeaders"
              :UploadComplete="uploadComplete" :FileUploaded="fileUploaded" :UploadProgress="uploadProgress"
              @inputUploader="inputUploader"/>

    <!--上传触发元素-->
    <div v-if="!filtButton" v-show="!hideUploadPlugin" class="unDataControl" :style="{
      'width':frameUpDataWidth ? frameUpDataWidth + 'px' : '100%',
      'height':frameUpDataHeight ? frameUpDataHeight + 'px' : '150px',
    }">
      <!--单个框框上传-->
      <template v-if="!threeFile">
        <div class="upDateClass" :id="upDateType" ref="upFile">
          <div class="el-icon-plus unDateIcon"></div>
        </div>
        <!--禁用上传-->
        <div v-show="(uploadNum != 0 ? tableData.length >= uploadNum  : false) || disabledBut" class="oneMasking"></div>
      </template>

      <!--三维文件上传-->
      <template v-else>
        <div class="flex_b_c threeUpData">
          <div id="2654" @click="setThreeType(0)" class="upDateClass threesag">
            <div class="el-icon-plus unDateIcon"></div>
            <div>高模</div>
          </div>
          <div id="86564" @click="setThreeType(1)" class="upDateClass threesag">
            <div class="el-icon-plus unDateIcon"></div>
            <div>中模</div>
          </div>
          <div id="5778" @click="setThreeType(2)" class="upDateClass threesag">
            <div class="el-icon-plus unDateIcon"></div>
            <div>低模</div>
          </div>
          <div></div>
        </div>
        <!--禁用上传-->
        <div v-show="disabledBut" class="item1Masking"></div>
        <div v-show="disabledBut" class="item2Masking"></div>
        <div v-show="disabledBut" class="item3Masking"></div>
      </template>
    </div>
    <!--按钮上传-->
    <div v-if="filtButton">
      <el-button :disabled="(uploadNum != 0 ? tableData.length >= uploadNum  : false) || disabledBut"
                 :id="upDateType" size="small" type="primary">{{ filtButtonName }}
      </el-button>
    </div>

    <!--格式限制-->
    <div v-show="allowFilesShow" class="uploadrestrictions">
            <span>支持格式：{{
                allowFiles.length ? allowFiles.join(',') + '文件' : '不限制'
              }}；{{ upFileSize ? `单个文件限${upFileSize}M；` : '' }}{{
                uploadNum ? `限${uploadNum}个文件` : '不限文件个数'
              }}。</span>
    </div>

    <!--上传文件列表-->
    <div v-show="uploadProgressShow">
      <div v-for="(item,index) in displayUpload" :key="item.name">
        <el-card style="margin-bottom: 10px">
          <div class="flex_b_c schedule">
            <div class="name"><i class="el-icon-document"></i><span class="omit">{{ item.name }}</span>
            </div>
            <div style="width: 15%">
              <span v-if="item.status === -1">正在计算MD5</span>
              <span v-if="item.status === 1 && item.percent === 0">等待上传</span>
              <span v-if="item.status === 4">上传失败</span>
              <span v-if="item.status === 5">上传成功</span>
            </div>
            <div style="width: 10%;" class="flex_b_c name">
              <div></div>
              <span v-if="item.status != 4 && item.status !== 5">
                <i style="cursor: pointer" v-if="!uploading" @click="uploadStart(index)"
                   class="el-icon-video-play"></i>
              <i style="cursor: pointer" v-else @click="uploadStop(index)"
                 class="el-icon-video-pause"></i>
              </span>
              <i style="cursor: pointer" class="el-icon-close"
                 @click="deleteFile(item.id, item)"></i>
            </div>
          </div>
          <el-progress v-show="item.percent != 100 && item.status != 4" :percentage="item.percent"></el-progress>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import FileMd5 from '@/utils/file-md5'
import Uploader from './Uploader'

export default {
  name: "resumeUpload",
  components: {Uploader},
  props: {
    //上传地址
    fetchUrl: {
      type: String,
    },

    //上传标识;解决同一个页面调用时多次弹出上传弹窗的问题
    upDateType: {
      type: String,
      default: '2609047847',
    },

    //上传文件数量，默认值为0（不限制），当设置后，超过的文件会删除
    uploadNum: {
      type: Number,
      default: 0,
    },

    //上传文件大小，默认值为0（不限制)M
    upFileSize: {
      type: Number,
      default: 0,
    },

    //上传文件格式限制，不设置则不限制
    allowFiles: {
      type: Array,
      default: () => [],
    },

    //上传文件格式限制(是否展示)
    allowFilesShow: {
      type: Boolean,
      default: true,
    },

    //是否是三维上传，默认不是
    threeFile: {
      type: Boolean,
      default: false,
    },

    //是否隐藏所有上传入口
    hideUploadPlugin: {
      type: Boolean,
      default: false,
    },

    //是否展示上传按钮，默认不是
    filtButton: {
      type: Boolean,
      default: false,
    },

    //展示上传按钮时按钮名称
    filtButtonName: {
      type: String,
      default: '选择文件',
    },

    //是否禁用上传
    disabledBut: {
      type: Boolean,
      default: false,
    },

    //是否展示默认上传进度，默认展示
    uploadProgressShow: {
      type: Boolean,
      default: true,
    },

    //文件上传成功后隐藏进度
    uploadProgressTypeShow: {
      type: Boolean,
      default: true,
    },

    //文件上传失败是否立马删除
    errorFileNoeDelete: {
      type: Boolean,
      default: true,
    },

    //是否上传成功后提示：‘上传成功’提示,默认提示
    showSuccessInfo: {
      type: Boolean,
      default: true,
    },

    //自定义上传单个框框的宽度 宽度默认100%
    frameUpDataWidth: {
      type: Number,
      default: 0, //px
    },
    //自定义上传单个框框的高度 高度默认150px
    frameUpDataHeight: {
      type: Number,
      default: 0, //px
    },


    //上传附带的自定义参数

    //藏品的上传：collection  档案的上传：archives 数字资源的上传：dyyg
    dir: {
      type: String,
      default: 'collection',
    },

    //文件类型（0 图片，1音频，2 视频，3文献，4鉴定资料,5其他，6三维，7证明资料）
    fileType: {
      type: Number,
      default: null,
    },

    //其它上传参数
    otherParameter: {
      type: Object,
    },
  },
  data() {
    return {
      //断点续传
      url: this.$globalUrl() + 'zyd-common/file/uploadThree', //上传地址
      updateSize: '50MB',//分片上传文件时，每片文件被切割成的大小，当该值为0时表示不使用分片上传功能
      uploading: false,//上传状态
      myfilters: { //单个上传配置
        // mime_types: [ //限定上传文件的类型
        //   {title: "filesType", extensions: "jpg,gif,png"},
        // ],
        // max_file_size: '2GB', //最大只能上传2GB的文件
        prevent_duplicates: true //不允许选取重复文件,
      },
      up: {},
      myHeaders: {
        //请求头
        Authorization: sessionStorage.getItem('token'),
        satoken: sessionStorage.getItem('token'),
      },
      //储存最后需要的数据
      tableData: [],
      //上传列表
      files: [],

      //三维上传触发
      threeBrowse: ['2654', '86564', '5778'],
      threeType: 0,
    }
  },

  computed: {
    //展示上传进度
    displayUpload() {
      let dataList = []
      if (this.uploadProgressTypeShow) {
        if (this.files && this.files.length != 0) {
          dataList = this.files.filter(item => {
            if (item.status != 5) {
              return item
            }
          })
        }
        return dataList
      } else {
        return this.files
      }
    }
  },

  // watch: {
  //   allowFiles: {
  //     handler(newName, oldName) {
  //       this.myfilters.mime_types[0].extensions = newName.join(',')
  //     },
  //     immediate: true, //首次绑定的时候，是否执行 handler
  //     deep: true,
  //   }
  // },

  methods: {
    //三维时点的是哪个上传 0高模，1中模，1低模
    setThreeType(num) {
      this.threeType = num
    },

    //之前的上传文件
    setOldFile(data) {
      if (data && data.length != 0) {
        data.forEach(item => {
          this.$set(item, 'status', 5)
        })
        this.tableData = JSON.parse(JSON.stringify(data))
      }
    },

    //删除上传数据
    deleteFile(id, data, type) {
      let onedata = ''
      if (!data) {
        this.tableData = this.tableData.filter((item, index) => {
          if (item.id == id) {
            onedata = item
          }
          return item.id != id
        })
      } else {
        onedata = data
      }
      this.up.removeFile(onedata)
      if (!type) {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }
    },

    //当文件添加到上传队列后触发
    filesAdded(up, files) {
      this.percent = 0
      //文件数量校验
      if (this.uploadNum != 0 && this.displayUpload.length + this.tableData.length > this.uploadNum) {
        let chaoNum = this.tableData.length + this.displayUpload.length - this.uploadNum
        for (let i = 0; i < chaoNum; i++) {
          this.up.removeFile(files[files.length - i - 1])
        }
        this.$message.error(`上传文件超过${this.uploadNum}个文件，已将超过的文件删除`)
      }
      files.forEach((f, index) => {
            //文件名长度校验
            if (f.name.substring(0, f.name.lastIndexOf('.')).length <= 80) {
              let filename = f.name
              var suffix = filename.substring(filename.lastIndexOf('.') + 1)
              var suf = suffix.toLowerCase()
              if (f.status == 4) {
                return
              }
              f.status = -1
              FileMd5(f.getNative(), (e, md5) => {
                    f['md5'] = md5
                    f.status = 1
                    //文件重复
                    if (this.tableData.length != 0) {
                      this.tableData.forEach(item => {
                        if (f.name == item.fileName && f.size == item.fileSize) {
                          this.up.removeFile(f)
                          this.$message.error(`上传文件${f.name}重复`)
                        }
                      })
                    }
                    //格式判断
                    if (this.selectRepeat(f)) {
                      this.up.removeFile(f)
                      this.$message.error('文件' + f.name + '不符合格式！')
                      f.status = 4
                    }
                    //文件大小判断
                    if (this.upFileSize && f.origSize > this.upFileSize * 1024 * 1024) {
                      this.$message.error('文件' + f.name + '超出限制大小！')
                      this.up.removeFile(f)
                      f.status = 4
                    }
                    //开始上传
                    setTimeout(() => {
                      this.uploadStart()
                    }, 200)
                  }
              )
            } else {
              this.$message.error('文件名过长')
              this.up.removeFile(f)
            }
          }
      )
    },

    //判断文件格式
    selectRepeat(item) {
      let repeat = false
      if (this.allowFiles.length) {
        let filename = item.name
        let suffix = filename.substring(filename.lastIndexOf('.') + 1)
        let suf = suffix.toLowerCase()
        let fileTypeList = this.allowFiles.map(item => {
          return item.toLowerCase()
        })
        if (fileTypeList.indexOf(suf) == -1) {
          repeat = true
        }
      }
      return repeat
    },

    //开始上传
    uploadStart() {
      console.log('=============')
      console.log(this.files)
      this.up.start()
      this.uploading = true
    },

    //当队列中的某一个文件正要开始上传前触发
    beforeUpload(up, file) {
      let dataUp = {
        'size': file.size,
        'md5': file.md5,
        'uploadPath': this.uploadPath,
        'dataid': '2609047847', //藏品id
        'dir': this.dir,
      }
      if (this.fileType != null) {
        this.$set(dataUp, 'fileType', this.fileType)
      }

      if (this.otherParameter) {
        Object.keys(this.otherParameter).forEach(key => {
          this.$set(dataUp, key, this.otherParameter[key])
        })
      }

      if (this.threeFile) {
        up.settings.url = this.$globalUrl() + 'zyd-common/file/uploadThreeFile'
        this.$set(dataUp, 'model', this.threeType)
      }
      if (this.fetchUrl) {
        up.settings.url = this.$globalUrl() + this.fetchUrl
        this.$set(dataUp, 'model', 1)
      } else {
        up.settings.url = this.$globalUrl() + 'zyd-common/file/uploadThree'
      }
      up.setOption('multipart_params', dataUp)
    },

    //当发生错误时触发
    error(uploader, errObject) {
      this.uploading = false
      switch (errObject.code) {
        case -100:
          this.$message.error('上传失败')
          break
        case -200:
          this.$message.error('http网络错误')
          break
        case -300:
          this.$message.error('发生磁盘读写错误时的错误代码，例如本地上某个文件不可读')
          break
        case -400:
          this.$message.error('发生因为安全问题而产生的错误')
          break
        case -500:
          this.$message.error('初始化时发生错误')
          break
        case -600:
          this.$message.error('选择的文件大于2GB，请重新选择')
          break
        case -601:
          this.$message.error('选择的文件类型不符合要求')
          break
        case -602:
          this.$message.error('选取了重复的文件')
          break
        case -700:
          this.$message.error('图片格式错误')
          break
        case -702:
          this.$message.error('文件大小超过系统所能处理的最大值')
          break
        default:
          this.$message.error('内存错误')
      }
    },

    //当上传队列中所有文件都上传完成后触发
    uploadComplete() {
      this.$emit('allFilesSuccessfully')
      this.uploading = false
    },

    //当队列中的某一个文件上传完成后触发
    fileUploaded(uploader, file, responseObject) {
      let obj = JSON.parse(responseObject.response)
      if (obj.status) { //上传成功
        if (this.threeFile) {
          this.files.forEach(item => {
            if (item.name == file.name) {
              this.$set(item, 'upDate', obj.data)
              this.$set(item, 'model', this.threeType)
              this.tableData.push(item)
            }
          })
        } else {
          this.files.forEach(item => {
            if (item.name == file.name) {
              item.percent = 100
              this.$set(item, 'upDate', obj.data)
              this.tableData.push(item)
            }
          })
        }
        if (this.showSuccessInfo) {
          // this.$message.success('上传成功')
          //多个上传只提示一次
          let suceMsg = this.files.filter(item => {
            return !item.hasOwnProperty('upDate')
          })
          if (!suceMsg.length) {
            this.$message.success('上传成功')
          }
        }
        this.$emit('upFileDate', this.tableData)
      } else { //上传失败
        if (this.errorFileNoeDelete) {
          this.up.removeFile(this.up.getFile(this.files[0].id))
        } else {
          this.files.forEach(item => {
            if (item.name == file.name) {
              item.status = 4
            }
          })
        }
        this.$message.error(obj.msg)
      }
    },

    //会在文件上传过程中不断触发，可以用此事件来显示上传进度
    uploadProgress(uploader, file) {
      this.bytesPerSec = this.up.total.bytesPerSec //文件大小
      if (this.bytesPerSec < 0.1 * 1024) {                            //小于0.1KB，则转化成B
        this.rate = this.bytesPerSec.toFixed(2) + 'B/s'
      } else if (this.bytesPerSec < 0.1 * 1024 * 1024) {            //小于0.1MB，则转化成KB
        this.rate = (this.bytesPerSec / 1024).toFixed(2) + 'KB/s'
      } else if (this.bytesPerSec < 0.1 * 1024 * 1024 * 1024) {        //小于0.1GB，则转化成MB
        this.rate = (this.bytesPerSec / (1024 * 1024)).toFixed(2) + 'MB/s'
      } else {                                            //其他转化成GB
        this.rate = (this.bytesPerSec / (1024 * 1024 * 1024)).toFixed(2) + 'GB/s'
      }

      if (file.size < 104857600) {
        this.percent = file.percent
        if (file.percent > 95) {
          file.percent = 95
        }
      } else {
        this.percent = file.percent
        if (file.percent > 95) {
          file.percent = 95
        }
        let uploaded = file.type
        this.percentflag = file.type * 1000 / 1000 > 0
        if (uploaded > 0) {
          let sjs = Math.round(Math.random() * 3)
          let p = (uploaded * 1000 / 1000) + sjs
          if (p >= 100) {
            file.type = 99
            this.percentUp = 99
          } else if (p < 100) {
            file.type = p
            this.percentUp = p
          }
        }
      }
    },

    inputUploader(up) {
      this.up = up
      this.files = up.files
    },

    //暂停上传
    uploadStop(index) {
      this.up.stop()
      this.uploading = false
    },

    //清空所有上传文件
    clearAllFile() {
      for (let i = this.files.length; i > 0; i--) {
        this.up.removeFile(this.files[i - 1])
      }
    },
  },
}
</script>

<style scoped lang="scss">
.unDataControl {
  position: relative;
}

.upDateClass {
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-size: 16px;
  display: grid;
  justify-items: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */

  .unDateIcon {
    font-size: 40px;
    color: #8c939d;
  }
}

.oneMasking {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(192, 200, 217, 0.3);
  z-index: 10;
}

.threeUpData {
  width: 100%;
  height: 100%;
}

.threesag {
  width: 32%;
  align-content: center;
}

.item1Masking {
  position: absolute;
  top: 0;
  left: 0;
  width: 32%;
  height: 100%;
  background: rgba(192, 200, 217, 0.3);
  z-index: 10;
}

.item2Masking {
  position: absolute;
  top: 0;
  left: calc(33% + 4px);
  width: 32%;
  height: 100%;
  background: rgba(192, 200, 217, 0.3);
  z-index: 10;
}

.item3Masking {
  position: absolute;
  top: 0;
  left: calc(66% + 8px);
  width: 32%;
  height: 100%;
  background: rgba(192, 200, 217, 0.3);
  z-index: 10;
}


.uploadrestrictions {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 13px;
  color: #606266;
}

.schedule {
  margin-right: 30px;

  .name {
    width: 60%;
    font-size: 14px;
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }
}
</style>

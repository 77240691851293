<template>
    <el-dialog
            title="鉴定结果"
            :visible.sync="dialogVisible"
            width="600px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <el-form :model="inputForm" ref="inputForm" :rules="rules" label-width="100px"
                 size="small">
            <el-form-item label="级别" prop="appraisalType">
                <el-select
                        v-model="inputForm.appraisalType"
                        clearable
                        :disabled="inputType"
                        placeholder="请选择级别"
                        style="width: 100%"
                >
                    <el-option
                            v-for="item in this.$dictUtils.getDictList('appraisal_level')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="鉴定说明" prop="disinfectDesc">
                <el-input
                        v-model="inputForm.disinfectDesc"
                        clearable
                        type="textarea"
                        :rows="3"
                        :resize="'none'"
                        maxlength="200"
                        :disabled="inputType"
                        style="width: 100%"
                        placeholder="请输入鉴定说明(限200字)">
                </el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input
                        v-model="inputForm.remark"
                        clearable
                        type="textarea"
                        :rows="3"
                        :resize="'none'"
                        maxlength="200"
                        :disabled="inputType"
                        style="width: 100%"
                        placeholder="请输入备注(限200字)">
                </el-input>
            </el-form-item>
            <el-form-item label="鉴定文件" prop="netUrl">
                <resume-upload :dir="'collection'" ref="resume-upload" :filtButton="true" :uploadNum="flieNumber" :allowFiles="fileType"
                               @upFileDate="(data)=>getUpData(data)"></resume-upload>
                <div v-for="(item,index) in fileList">
                    <el-card class="box-card">
                        <div class="flex_b_c">
                            <div class="el-icon-notebook-2" style="cursor: pointer" @click="previewPdf(item.netUrl)"></div>
                            <div style="flex: 1;margin: 0 20px;cursor: pointer" @click="previewPdf(item.netUrl)">
                                <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top-start">
                                    <div class="omit">{{ item.fileName }}</div>
                                </el-tooltip>
                            </div>
                            <div class="el-icon-circle-close" style="cursor: pointer"
                                 @click="deteliFile(item,index)"></div>
                        </div>
                    </el-card>
                </div>
            </el-form-item>
        </el-form>
        <div class="text_center" style="margin-top: 20px">
            <el-button size="small" @click="handleClose()">取消</el-button>
            <el-button size="small" type="primary" @click="sureData()">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import ResumeUpload from "@/components/Upload/resumeUpload.vue";
import {Base64} from 'js-base64'

export default {
    name: "appraisalResults",
    components: {ResumeUpload},
    data() {
        return {
            dialogVisible: false,
            inputForm: {
                appraisalType: '',
                disinfectDesc: '',
                remark: '',
                netUrl: '',
            },
            rules: {
                appraisalType: [
                    {required: true, message: "请选择级别", trigger: "change"},
                ],
                netUrl: [
                    {required: true, message: "请上传鉴定文件", trigger: "change"},
                ],
            },
            inputType: false,

            //文件上传
            flieNumber: 1,
            fileList: [],
            fileType: ['PDF'],
            rowData: {},
            selectTab: 0,
            appraisalId:null,
        }
    },

    methods: {
        init(row,appraisalId, selectTab) {
            console.log(row)
            this.rowData = row
            this.selectTab = selectTab
            this.appraisalId = appraisalId
            this.fileList = []
            //查询详情
            let query = {
                collectionId:row.id,
                appraisalId:appraisalId
            }
            this.$axios(this.api.collection.collectionappraisalresultGetById, query, 'get').then(data => {
                if (data.status) {
                    this.dialogVisible = true
                    this.inputForm.disinfectDesc = data.data.appraisalExplain
                    this.inputForm.remark = data.data.remark
                    this.inputForm.appraisalType = data.data.collectionLevel
                    this.inputForm.netUrl = data.data.netUrl
                    this.inputForm.id = data.data.id
                    this.fileList.push({
                        fileName: data.data.fileName,
                        fileFormat: data.data.fileFormat,
                        filePath: data.data.filePath,
                        fileMd5: data.data.fileMd5,
                        netUrl: data.data.netUrl,
                        fileSize: data.data.fileSize,
                        fileSizeFormat: data.data.fileSizeFormat,
                        id: data.data.id,
                    })
                    this.$refs['resume-upload'].setOldFile(this.fileList)
                }
            })
        },

        //pdf预览
        previewPdf(url) {
            this.pdfUrl = url
            window.open(this.$pdfPreviewUrl() + 'onlinePreview?url=' + encodeURIComponent(Base64.encode(this.pdfUrl)));
        },

        //删除文件
        deteliFile(row, index) {
            this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.fileList.splice(index, 1)
                this.inputForm.netUrl = ''
                this.$refs["resume-upload"].deleteFile(row.fileID ? row.fileID : row.id)
            }).catch(() => {
            })
        },

        getUpData(data) {
            this.fileList = data.map((item, index) => {
                if (item.upDate) {
                    this.$set(item.upDate, 'fileID', item.id)
                    this.$set(item.upDate, 'fileSort', index + 1)
                    this.$set(item.upDate, 'masterView', 1)
                    this.$set(item.upDate, 'fileSize', item.origSize)
                    return item.upDate
                } else {
                    return item
                }
            })
            this.inputForm.netUrl = this.fileList[0].netUrl
            console.log(this.fileList)
        },

        //保存
        sureData() {
            this.$refs['inputForm'].validate((valid) => {
                if (valid) {
                    let upUrl = {}
                    if (this.fileList && this.fileList.length) {
                        upUrl = this.fileList[0]
                    }
                    let data = {
                        id: this.inputForm.id,
                        appraisalExplain: this.inputForm.disinfectDesc,
                        collectionId: this.rowData.id,
                        appraisalId:this.appraisalId,
                        remark: this.inputForm.remark,
                        collectionLevel: this.inputForm.appraisalType,
                        fileFormat: upUrl.fileFormat,
                        fileName: upUrl.fileName,
                        filePath: upUrl.filePath,
                        fileMd5: upUrl.fileMd5,
                        netUrl: upUrl.filePath,
                        fileSize: upUrl.fileSize,
                        fileSizeFormat: upUrl.fileSizeFormat,
                        fileType: this.selectTab == 4 ? 7 : 4,
                    }
                    let fechUrl = this.api.collection.collectionappraisalresultSave
                    let medthch = 'post'
                    if (this.inputForm.id) {
                        fechUrl = this.api.collection.collectionappraisalresultUpdateById
                        medthch = 'put'
                    }
                    this.$axios(fechUrl, data, medthch).then(data => {
                        if (data.status) {
                            if (this.inputForm.id) {
                                this.$message.success('修改成功')
                            } else {
                                this.$message.success('保存成功')
                            }
                            this.$emit('refresh')
                            this.handleClose()
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                } else {
                    return false;
                }
            });
        },

        handleClose() {
            if (this.fileList.length) {
                this.$refs["resume-upload"].deleteFile(this.fileList[0].fileID ? this.fileList[0].fileID : this.fileList[0].id, '', 1)
                this.fileList.splice(0, 1)
            }
            this.$refs.inputForm.resetFields()
            this.inputForm = {
                appraisalType: '',
                disinfectDesc: '',
                remark: '',
                netUrl: '',
            }
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>
.box-card ::v-deep .el-card__body {
    padding: 10px !important;
}
</style>
